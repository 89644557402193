import { Client, GetConfig } from 'client'
import { Optional } from 'utils/type-utils'
import { Token } from './token'

export interface TemplateCustomToken {
  default_value: string
  description?: string
  input_type: Token.Type.TEXT
  key: string
  mandatory?: boolean
  name: string
  template_id: string
}

export namespace TemplateCustomToken {
  export type Id = Pick<TemplateCustomToken, 'key'>
  export const INPUT_TYPES = [{ value: Token.Type.TEXT, label: 'Text' }]
  export const pickKey = (template: TemplateCustomToken) => template.key
  export const byKey = (id: string) => (token: TemplateCustomToken) => pickKey(token) === id

  export interface Field {
    label: string
    name: string
    description?: string
    default_value?: string
    type: Token.Type
    config: Pick<
      Token,
      'role' | 'required' | 'validator_id' | 'lock_to_sign_date' | 'custom_defined_option'
    > &
      Optional<Pick<Token.TokenRadio, 'radio'>, 'radio'>
  }

  export interface Group {
    name: string
    fields: Field[]
  }

  export function toField({
    name,
    required = true,
    type = Token.Type.TEXT,
    label,
    role = Token.Role.API,
    description,
    default_value,
    ...config
  }: {
    name: string
    type?: Token.Type
    label: string
    description?: string
    default_value?: string
  } & Partial<Field['config']>): Field {
    return {
      name,
      type,
      label,
      description,
      default_value,
      config: {
        validator_id: '',
        lock_to_sign_date: false,
        custom_defined_option: false,
        ...config,
        required,
        role,
      },
    }
  }

  export const toGroup = (customTokens: TemplateCustomToken[]): Group => ({
    name: 'Custom Tokens',
    fields: customTokens.map((customToken) =>
      toField({
        description: customToken.description,
        default_value: customToken.default_value,
        label: customToken.name,
        name: customToken.key,
        type: Token.Type.TEXT,
        required: customToken.mandatory,
      }),
    ),
  })

  export const findFieldByName = (groups: Group[], name: string) => {
    const baseName = name.replace(/-.*/, '')
    for (let group of groups) {
      const field = group.fields.find((field) => field.name === baseName)
      if (field) return field
    }
    return undefined
  }

  export const getCoreName = (name: string) => {
    let baseName = name.replace(/-.*/, '')
    return baseName.replace(/(owner_)|(tenant\d_)|(guarantor\d_)/, '')
  }

  export const createBaseName = (coreName: string, role?: Token.Role) =>
    !role || role === Token.Role.API ? coreName : `${role.toLowerCase()}_${coreName}`

  const ROLES = [
    Token.Role.OWNER,
    Token.Role.T1,
    Token.Role.T2,
    Token.Role.T3,
    Token.Role.T4,
    Token.Role.T5,
    Token.Role.T6,
    Token.Role.G1,
    Token.Role.G2,
    Token.Role.G3,
    Token.Role.G4,
    Token.Role.G5,
    Token.Role.G6,
  ] as const

  const roleToLabel = (role: Token.Role) => String(role).replace(/(\d)$/, ' $1')
  const roleToPosessive = (role: Token.Role) =>
    role === Token.Role.ADMIN
      ? ''
      : role === Token.Role.OWNER
      ? "Owner's "
      : role.replace(/^(\w)\w*(\d)$/, "$1$2's ")

  export const ROLE_OPTIONS = [Token.Role.ADMIN, ...ROLES].map((role) => ({
    label: roleToLabel(role),
    value: role,
  }))

  export const DEFAULT_GROUPS: Group[] = [
    {
      name: 'Dates',
      fields: [
        {
          label: 'Start',
          description: 'Lease Start',
          name: createBaseName('lease_start', Token.Role.API),
          type: Token.Type.DATE,
        },
        {
          label: 'End',
          description: 'Lease End',
          name: createBaseName('lease_end', Token.Role.API),
          type: Token.Type.DATE,
        },
        { label: 'Years', name: createBaseName('years', Token.Role.API) },
        { label: 'Months', name: createBaseName('month', Token.Role.API) },
        { label: 'Days', name: createBaseName('days', Token.Role.API) },
        {
          label: 'Lease Date',
          description: 'Lease Signing Date',
          name: createBaseName('signature_date', Token.Role.API),
          type: Token.Type.DATE,
        },
      ].map(toField),
    },
    {
      name: 'Lease',
      fields: [
        { label: 'Rent', name: createBaseName('rent', Token.Role.API) },
        { label: 'Yearly Rent', name: createBaseName('yearly_rent', Token.Role.API) },
        { label: 'Deposit', name: createBaseName('deposit', Token.Role.API) },
      ].map(toField),
    },
    {
      name: 'Unit',
      fields: [
        { name: createBaseName('unit_number', Token.Role.API), label: 'Unit Number' },
        { name: createBaseName('unit_address', Token.Role.API), label: 'Address' },
        { name: createBaseName('street', Token.Role.API), label: 'Street' },
        { name: createBaseName('borough', Token.Role.API), label: 'District' },
        { name: createBaseName('city', Token.Role.API), label: 'City' },
        { name: createBaseName('state', Token.Role.API), label: 'State' },
        { name: createBaseName('zip', Token.Role.API), label: 'Zip' },
        { name: createBaseName('floor', Token.Role.API), label: 'Floor' },
      ].map(toField),
    },
    {
      name: 'Previous Rent',
      fields: [
        { name: createBaseName('previous_rent', Token.Role.API), label: 'Prev. Rent' },
        { name: createBaseName('previous_start_date', Token.Role.API), label: 'Prev. Start' },
        { name: createBaseName('previous_end_date', Token.Role.API), label: 'Prev. End' },
        { name: createBaseName('previous_deposit', Token.Role.API), label: 'Prev. Deposit' },
      ].map(toField),
    },

    {
      name: roleToLabel(Token.Role.ADMIN),
      fields: [
        {
          name: createBaseName('text', Token.Role.ADMIN),
          label: 'Text',
          type: Token.Type.TEXT,
          config: {
            role: Token.Role.ADMIN,
            required: true,
            validator_id: '',
            lock_to_sign_date: false,
            custom_defined_option: false,
          },
        },
        {
          label: 'Date',
          name: createBaseName('date', Token.Role.ADMIN),
          type: Token.Type.DATE,
          config: {
            role: Token.Role.ADMIN,
            required: true,
            validator_id: '',
            lock_to_sign_date: true,
            custom_defined_option: false,
          },
        },
        {
          name: createBaseName('radio', Token.Role.ADMIN),
          label: 'Radio',
          type: Token.Type.RADIO,
          config: {
            role: Token.Role.ADMIN,
            required: true,
            validator_id: '',
            lock_to_sign_date: false,
            custom_defined_option: false,
          },
        },
        {
          name: createBaseName('checkbox', Token.Role.ADMIN),
          label: 'Check',
          type: Token.Type.CHECKBOX,
          config: {
            role: Token.Role.ADMIN,
            required: true,
            validator_id: '',
            lock_to_sign_date: false,
            custom_defined_option: false,
          },
        },
      ],
    },

    ...ROLES.map((role) => ({
      name: roleToLabel(role),
      fields: [
        {
          label: roleToPosessive(role) + 'Name',
          name: createBaseName('name', role),
          type: Token.Type.TEXT,
          config: {
            role: Token.Role.API,
            required: true,
            validator_id: '',
            lock_to_sign_date: false,
            custom_defined_option: false,
          },
        },
        {
          label: roleToPosessive(role) + 'Address',
          name: createBaseName('address', role),
          type: Token.Type.TEXT,
          config: {
            role: Token.Role.API,
            required: true,
            validator_id: '',
            lock_to_sign_date: false,
            custom_defined_option: false,
          },
        },
        {
          label: 'Signature',
          name: createBaseName('signature', role),
          type: Token.Type.SIGNATURE,
          config: {
            role,
            required: true,
            validator_id: '',
            lock_to_sign_date: false,
            custom_defined_option: false,
            height: 24,
          },
        },
        {
          label: 'Initials',
          name: createBaseName('initials', role),
          type: Token.Type.SIGNATURE,
          config: {
            role,
            required: true,
            validator_id: '',
            lock_to_sign_date: false,
            custom_defined_option: false,
            width: 30,
            height: 24,
          },
        },
        {
          label: 'Sign Date',
          name: createBaseName('signature_date', role),
          type: Token.Type.DATE,
          config: {
            role,
            required: true,
            validator_id: '',
            lock_to_sign_date: true,
            custom_defined_option: false,
          },
        },
        {
          name: createBaseName('radio', role),
          label: 'Radio',
          type: Token.Type.RADIO,
          config: {
            role,
            required: true,
            validator_id: '',
            lock_to_sign_date: false,
            custom_defined_option: false,
          },
        },
        {
          name: createBaseName('text', role),
          label: 'Text',
          type: Token.Type.TEXT,
          config: {
            role,
            required: true,
            validator_id: '',
            lock_to_sign_date: false,
            custom_defined_option: false,
          },
        },
        {
          name: createBaseName('checkbox', role),
          label: 'Check',
          type: Token.Type.CHECKBOX,
          config: {
            role,
            required: true,
            validator_id: '',
            lock_to_sign_date: false,
            custom_defined_option: false,
          },
        },
      ],
    })),
  ]
}

class CustomTokenBackend extends Client {
  list = async (config?: GetConfig) => {
    type CustomToken = {
      category: string
      data_type: string
      description?: string
      label?: string
      name: string
      roles: Token.Role[]
    }
    type Res = {
      tokens: CustomToken[]
      status: string
    }
    const { tokens } = await this.get<Res>('/lease/tokens/get', undefined, config)

    const categoryMap: Record<string, CustomToken[]> = {}

    tokens.forEach((token) => {
      if (!categoryMap[token.category]) {
        categoryMap[token.category] = []
      }
      categoryMap[token.category].push(token)
    })

    return tokens
  }
}

export const customToken = new CustomTokenBackend()
