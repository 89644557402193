/* eslint-disable no-console */
import { PdfPageDefinition } from '@rello/pdf'
import { Template, TemplateCustomToken } from 'api/admin'
import { Radio, Token } from 'api/template'
import { createContext, useContext, useMemo } from 'react'
import { isSelected, itemToSelection } from './selectors'
import {
  Alignment,
  EditorAction,
  EditorActionOf,
  EditorActionType,
  EditorState,
  FieldCreate,
  SelectionModifier,
  SelectionRect,
  Update,
  WorkspaceSelection,
} from './types'

export const useEditorActions = (dispach: React.Dispatch<EditorAction>) => {
  return useMemo(
    () => ({
      setPages(pages: PdfPageDefinition[]) {
        dispach({ type: EditorActionType.SET_PAGES, pages })
      },
      select(selection: WorkspaceSelection | WorkspaceSelection[], modifier?: SelectionModifier) {
        dispach({ type: EditorActionType.SELECT, selection, modifier })
      },
      selectRect(page: number, rect: SelectionRect, modifier?: SelectionModifier) {
        dispach({ type: EditorActionType.SELECT_RECTANGLE, page, rect, modifier })
      },
      alignSelection(alignment: Alignment) {
        dispach({ type: EditorActionType.ALIGN_SELECTION, alignment })
      },
      create({ field, result }: FieldCreate) {
        dispach({ type: EditorActionType.CREATE, field, result })
      },
      clearSelection() {
        dispach({ type: EditorActionType.CLEAR_SELECTION })
      },
      updateSelected(update: Update<Token> | Update<Radio>) {
        dispach({ type: EditorActionType.UPDATE_SELECTED, update })
      },
      updateToken(update: Update<Token>, token_id: string) {
        dispach({ type: EditorActionType.UPDATE_TOKEN, update, token_id })
      },
      deleteTokenById(token_id: string) {
        dispach({ type: EditorActionType.DELETE_TOKEN, token_id })
      },
      updateRadioById(update: Update<Radio>, token_id: string, radio_id: string) {
        dispach({ type: EditorActionType.UPDATE_RADIO, update, token_id, radio_id })
      },
      deleteRadioById(token_id: string, radio_id: string) {
        dispach({ type: EditorActionType.DELETE_RADIO, token_id, radio_id })
      },
      addRadioByIndex(token_id: string) {
        dispach({ type: EditorActionType.ADD_RADIO, token_id })
      },
      deleteSelected() {
        dispach({ type: EditorActionType.DELETE_SELECTED })
      },
      undo() {
        dispach({ type: EditorActionType.UNDO })
      },
      redo() {
        dispach({ type: EditorActionType.REDO })
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )
}

const EditorApiContext = createContext<null | ReturnType<typeof useEditorActions>>(null)
export const EditorApiProvider = EditorApiContext.Provider
export const useEditorApi = () => {
  return useContext(EditorApiContext)
}

const StateContext = createContext<EditorState>({
  fields: [],
  selection: undefined,
  tokens: [],
  history: [],
  historyIndex: -1,
  pages: [],
  template: {} as Template,
})

export const StateProvider = StateContext.Provider

export const useEditorState = () => {
  return useContext(StateContext)
}

export function getInitialState(
  template: Template,
  fields = TemplateCustomToken.DEFAULT_GROUPS,
): EditorState {
  const tokens = Token.init(template.tokens_config ?? [], template)
  return {
    tokens,
    selection: undefined,
    fields,
    history: [{ tokens }],
    historyIndex: 0,
    pages: [],
    template,
  }
}

function resolve<T extends EditorActionType>(
  actionType: T,
  reducer: (state: EditorState, action: EditorActionOf<T>) => EditorState,
) {
  return (state: EditorState, action: EditorAction) => {
    return action.type === actionType ? reducer(state, action as EditorActionOf<T>) : state
  }
}

export const editorReducer = (state: EditorState, action: EditorAction): EditorState => {
  return [
    resolve(EditorActionType.SET_PAGES, (state, { pages }) => {
      const tokens = state.tokens.map((token) => {
        let fixedItem = getFixedOutboundsItem(token, pages) as Token
        if (Token.isRadioToken(token)) {
          let radiosChanged = false
          const fixedRadios = token.radio.map((radio) => {
            const fixedItem = getFixedOutboundsItem(radio, pages) as Radio
            if (fixedItem) {
              radiosChanged = true
              return fixedItem
            }
            return radio
          })
          if (radiosChanged) {
            fixedItem = { ...(fixedItem as Token.TokenRadio), radio: fixedRadios }
          }
        }
        if (fixedItem) {
          return fixedItem
        }
        return token
      })

      return {
        ...state,
        tokens,
        pages,
      }
    }),
    resolve(EditorActionType.UNDO, (state) => {
      if (state.historyIndex <= 0) return state
      const prevState = state.history[state.historyIndex - 1]
      return {
        ...state,
        ...prevState,
        historyIndex: state.historyIndex - 1,
      }
    }),
    resolve(EditorActionType.REDO, (state) => {
      if (state.historyIndex >= state.history.length - 1) return state
      const nextState = state.history[state.historyIndex + 1]
      return {
        ...state,
        ...nextState,
        historyIndex: state.historyIndex + 1,
      }
    }),
    resolve(EditorActionType.SELECT, (state, action) => {
      const selection = selectionAsArray(action.selection)
      switch (action.modifier) {
        case SelectionModifier.ADD:
          return {
            ...state,
            selection: joinSelections(state.selection, selection),
          }
        case SelectionModifier.REMOVE:
          return {
            ...state,
            selection: extractSelection(state.selection, selection),
          }
        default:
          return { ...state, selection }
      }
    }),
    resolve(EditorActionType.SELECT_RECTANGLE, (state, action) => ({
      ...state,
      selection: getSelectionByPageRect(action.page, action.rect, state.tokens),
    })),
    resolve(EditorActionType.CLEAR_SELECTION, (state) => ({
      ...state,
      selection: undefined,
    })),
    resolve(EditorActionType.CREATE, (state, action) => {
      const token = Token.createToken(
        {
          page_number: action.result.page_number,
          x: action.result.x,
          y: action.result.y,
          _index: state.tokens.length,
        },
        action.field,
        state.template,
      )
      const tokens = Token.sortTokens([...state.tokens, token])
      return {
        ...state,
        tokens,
        selection: [
          Token.isRadioToken(token)
            ? itemToSelection({ token, radio: token.radio[0] })
            : itemToSelection({ token }),
        ],
      }
    }),
    resolve(EditorActionType.UPDATE_SELECTED, (state, action) => {
      if (!state.selection) return state
      let tokens2 = withSelectedItems(state.tokens, state.selection, action.update)
      return {
        ...state,
        tokens: tokens2,
      }
    }),
    resolve(EditorActionType.DELETE_SELECTED, (state) => {
      if (!state.selection) return state
      return {
        ...state,
        selection: undefined,
        tokens: Token.init(deleteSelected(state.tokens, state.selection), state.template),
      }
    }),
    resolve(EditorActionType.UPDATE_TOKEN, (state, action) => {
      const tokens = applyUpdateByIndex(
        state.tokens,
        action.update,
        findTokenIndexById(state.tokens, action.token_id),
      )
      return {
        ...state,
        tokens,
      }
    }),
    resolve(EditorActionType.ADD_RADIO, (state, action) => {
      const index = findTokenIndexById(state.tokens, action.token_id)
      const token = state.tokens[index]
      if (!Token.isRadioToken(token)) return state
      const lastRadio = token.radio.at(-1)
      if (!lastRadio) return state

      const radio = Token.createRadio(
        {
          page_number: lastRadio.page_number,
          value: '',
          y: lastRadio.y + lastRadio.height + 10,
          x: lastRadio.x,
          _radioIndex: lastRadio._radioIndex + 1,
        },
        token,
      )
      while (token.radio.some(Token.byRadioId(radio.radio_id))) {
        radio.radio_id = Token.createRadioId(token)
      }

      return {
        ...state,
        tokens: applyUpdateByIndex(state.tokens, { radio: [...token.radio, radio] }, index),
        selection: [itemToSelection({ token, radio })],
      }
    }),
    resolve(EditorActionType.UPDATE_RADIO, (state, action) => {
      const tokens = applyUpdateByIndex(
        state.tokens,
        (token) =>
          Token.isRadioToken(token)
            ? {
                ...token,
                radio: applyUpdateByIndex(
                  token.radio,
                  action.update,
                  findRadioIndexById(token.radio, action.radio_id),
                ),
              }
            : token,
        findTokenIndexById(state.tokens, action.token_id),
      )
      return {
        ...state,
        tokens,
      }
    }),
    resolve(EditorActionType.DELETE_RADIO, (state, action) => {
      const index = findTokenIndexById(state.tokens, action.token_id)
      const token = state.tokens[index]
      // ignore if token is not radio
      if (!token || !Token.isRadioToken(token)) return state
      // remove token if only one radio left
      if (token.radio.length === 1) {
        return {
          ...state,
          tokens: Token.init(deleteByIndex(state.tokens, index), state.template),
          selection: isSelected(state.selection, action.token_id) ? undefined : state.selection,
        }
      }
      const tokens = applyUpdateByIndex(
        state.tokens,
        (token) => {
          if (!Token.isRadioToken(token)) return token
          const radioIndex = findRadioIndexById(token.radio, action.radio_id)
          const radio = deleteByIndex(token.radio, radioIndex)
          if (radioIndex === 0) {
            const { x, y, width, height, page_number } = radio[0]
            return { x, y, width, height, page_number, radio }
          }
          return { radio }
        },
        index,
      )
      return {
        ...state,
        tokens,
      }
    }),
    resolve(EditorActionType.DELETE_TOKEN, (state, action) => {
      const index = findTokenIndexById(state.tokens, action.token_id)
      const tokens = Token.init(deleteByIndex(state.tokens, index), state.template)
      const selection = isSelected(state.selection, action.token_id) ? undefined : state.selection
      return { ...state, tokens, selection }
    }),
    resolve(EditorActionType.ALIGN_SELECTION, (state, action) => {
      if (!state.selection) return state
      let [first, ...selection] = state.selection
      let bounds = getSelectionRectBounds(state.tokens, first)
      if (!bounds) return state
      switch (action.alignment) {
        case Alignment.H_LEFT:
          return {
            ...state,
            tokens: withSelectedItems(state.tokens, selection, () => ({ x: bounds!.x })),
          }
        case Alignment.H_RIGHT:
          let right = bounds.x + bounds.width
          return {
            ...state,
            tokens: withSelectedItems(state.tokens, selection, (token: Token | Radio) => ({
              x: right - token.width,
            })),
          }
        case Alignment.H_CENTER:
          let center = bounds.x + bounds.width / 2
          return {
            ...state,
            tokens: withSelectedItems(state.tokens, selection, (token: Token | Radio) => ({
              x: Math.round(center - token.width / 2),
            })),
          }
        case Alignment.V_TOP:
          return {
            ...state,
            tokens: withSelectedItems(state.tokens, selection, () => ({ y: bounds!.y })),
          }
        case Alignment.V_BOTTOM:
          let bottom = bounds.y + bounds.height
          return {
            ...state,
            tokens: withSelectedItems(state.tokens, selection, (token: Token | Radio) => ({
              y: bottom - token.height,
            })),
          }
        case Alignment.V_CENTER:
          let middle = bounds.y + bounds.height / 2
          return {
            ...state,
            tokens: withSelectedItems(state.tokens, selection, (token: Token | Radio) => ({
              y: Math.round(middle - token.height / 2),
            })),
          }

        default:
          return state
      }
    }),
    (state: EditorState, action: EditorAction) => {
      switch (action.type) {
        case EditorActionType.ALIGN_SELECTION:
        case EditorActionType.CREATE:
        case EditorActionType.DELETE_RADIO:
        case EditorActionType.DELETE_SELECTED:
        case EditorActionType.DELETE_TOKEN:
        case EditorActionType.UPDATE_RADIO:
        case EditorActionType.UPDATE_SELECTED:
        case EditorActionType.UPDATE_TOKEN:
          return {
            ...state,
            history: [...state.history.slice(0, state.historyIndex + 1), pickHisoryState(state)],
            historyIndex: state.historyIndex + 1,
          }

        case EditorActionType.SELECT:
        case EditorActionType.SELECT_RECTANGLE:
        case EditorActionType.CLEAR_SELECTION:
          return {
            ...state,
            historyIndex: state.historyIndex,
            history: applyUpdateByIndex(state.history, pickHisoryState(state), state.historyIndex),
          }

        default:
          return state
      }
    },
  ].reduce((state, fn) => fn(state, action), state)
}

const pickHisoryState = ({ tokens, selection }: EditorState) => ({
  tokens,
  selection,
})

const findTokenIndexById = (tokens: (Pick<Token, 'token_id'> | undefined)[], token_id: string) =>
  tokens.findIndex((token) => token?.token_id === token_id)

const findRadioIndexById = (radios: Radio[], radio_id: string) =>
  radios.findIndex(Token.byRadioId(radio_id))

const getSelectionRectBounds = (tokens: Token[], selection: WorkspaceSelection) => {
  const index = findTokenIndexById(tokens, selection.token_id)
  const token = tokens[index]
  if (!token) return undefined

  if (Token.isRadioToken(token) && selection.radio_id !== undefined) {
    const radio = token.radio[findRadioIndexById(token.radio, selection.radio_id)]
    if (!radio) return undefined
    const { x, y, width, height } = radio
    return { x, y, width, height }
  }

  const { x, y, width, height } = token
  return { x, y, width, height }
}

function withSelectedItems(
  tokens: Token[],
  selection: WorkspaceSelection[],
  update: Update<Token> | Update<Radio>,
): Token[] {
  if (!selection?.length) return tokens
  return selection.reduce((tokens, { token_id, radio_id }) => {
    const tokenIndex = findTokenIndexById(tokens, token_id)
    const token = tokens[tokenIndex]
    if (!token) return tokens
    if (typeof radio_id === 'string') {
      if (!Token.isRadioToken(token)) return tokens
      const radioIndex = findRadioIndexById(token.radio, radio_id)
      const updatedField = {
        radio: applyUpdateByIndex(token.radio, update as Update<Radio>, radioIndex),
      }
      if (radioIndex === 0) {
        const { x, y, width, height, size, page_number } = updatedField.radio[0]
        Object.assign(updatedField, { x, y, width, height, size, page_number })
      }
      return applyUpdateByIndex(tokens, updatedField, tokenIndex)
    }
    return applyUpdateByIndex(tokens, update as Update<Token>, tokenIndex)
  }, tokens)
}

function deleteSelected(tokens: Token[], selection: WorkspaceSelection[]): Token[] {
  if (!selection?.length) return tokens
  return selection.reduce(
    (tokens, { token_id, radio_id }) => {
      const tokenIndex = findTokenIndexById(tokens, token_id)
      if (!tokens[tokenIndex]) return tokens
      const token = tokens[tokenIndex]
      if (!token) return tokens
      if (typeof radio_id === 'string' && Token.isRadioToken(token)) {
        return applyUpdateByIndex(
          tokens,
          { radio: deleteByIndex(token.radio, findRadioIndexById(token.radio, radio_id)) },
          tokenIndex,
        )
      }
      return deleteByIndex(tokens, tokenIndex)
    },
    [...tokens],
  )
}

function applyUpdate<T>(item: T, update: Update<T>): T {
  return typeof update === 'function' ? { ...item, ...update(item) } : { ...item, ...update }
}

function applyUpdateByIndex<T>(items: T[], update: Update<T>, index: number): T[] {
  if (index < 0 || index >= items.length) return items
  const result = [...items]
  result.splice(index, 1, applyUpdate(items[index], update))
  return result
}

function deleteByIndex<T>(items: T[], index: number): T[] {
  const result = [...items]
  result.splice(index, 1)
  return result
}

function isInsideRectFactory(rect: SelectionRect) {
  return ({ x, y, width, height }: Token | Radio) => {
    if (x + width < rect.left || x > rect.left + rect.width) return false
    if (y + height < rect.top || y > rect.top + rect.height) return false
    return true
  }
}

function getSelectionByPageRect(page: number, rect: SelectionRect, tokens: Token[]) {
  let isInside = isInsideRectFactory(rect)
  return tokens.reduce((selection, token) => {
    if (token.page_number !== page) return selection
    if (Token.isRadioToken(token)) {
      token.radio.forEach((radio) => {
        if (isInside(radio)) {
          selection.push({ token_id: token.token_id, radio_id: radio.radio_id })
        }
      })
    } else if (isInside(token)) {
      selection.push({ token_id: token.token_id })
    }
    return selection
  }, [] as WorkspaceSelection[])
}

function getFixedOutboundsItem(item: Token | Radio, pages: PdfPageDefinition[]) {
  const lastPage = pages.length - 1
  let fixed = false
  const fixedItem = {
    _fixed: {} as Partial<Token | Radio>,
    ...item,
  }
  if (item.page_number > lastPage) {
    fixed = true
    fixedItem._fixed.page_number = item.page_number
    fixedItem.page_number = lastPage
  }
  const { width, height } = pages[fixedItem.page_number ?? item.page_number]
  if (item.x < 0) {
    fixed = true
    fixedItem._fixed.x = item.x
    fixedItem.x = 0
  } else if (item.x + item.width > width) {
    fixed = true
    fixedItem._fixed.x = item.x
    fixedItem.x = width - item.width
  }
  if (item.y < 0) {
    fixed = true
    fixedItem._fixed.y = item.y
    fixedItem.y = 0
  } else if (item.y + item.height > height) {
    fixed = true
    fixedItem._fixed.y = item.y
    fixedItem.y = height - item.height
  }
  return fixed ? fixedItem : undefined
}

function joinSelections(
  selection1: WorkspaceSelection[] | undefined,
  selection2: (WorkspaceSelection | undefined)[] | undefined,
) {
  const result = selection1 ?? ([] as WorkspaceSelection[])
  if (!selection2) return result
  selection2.forEach((selection) => {
    if (!selection) return
    if (!isSelected(result, selection.token_id, selection.radio_id)) result.push(selection)
  })
  return result.length ? result : undefined
}

function extractSelection(
  selection1: WorkspaceSelection[] | undefined,
  selection2: WorkspaceSelection[] | undefined,
) {
  if (!selection1) return undefined
  if (!selection2) return selection1
  const result = selection1.filter(
    (selection) => !isSelected(selection2, selection.token_id, selection.radio_id),
  )
  return result.length ? result : undefined
}

function selectionAsArray(selection: WorkspaceSelection | WorkspaceSelection[] | undefined) {
  if (Array.isArray(selection)) return selection.length ? selection : undefined
  return selection ? [selection] : undefined
}
