import { Client, GetConfig, PostConfig, UPLOAD_TIMEOUT } from 'client'
import { ListQuery, Order, createPaginatedList, parseOrder, parsePagination } from 'utils/list'
import { Optional } from 'utils/type-utils'
import { TemplateCustomToken } from './template-custom-token.admin'
import { Token } from './token'
import { AdminUser } from '../user/user.admin'

export interface Template {
  contract_dynamic_values: Record<string, string>
  contract_template_empty: string
  contract_template: string
  created_at: string
  custom_tokens?: TemplateCustomToken[]
  description: string
  name: string
  owner_id: string
  num_of_pages: number
  template_id: string
  tokens_config?: null | Token.Raw[]
  readonly max_tenants: number
  readonly max_guarantors: number
}

export namespace Template {
  export type IdField = 'template_id'
  export type Id = Pick<Template, IdField>
  export type Sort = 'created_at' | 'name' | 'page_count' | 'filename'
  export type Query = ListQuery<
    Sort,
    {
      name?: string
      global?: string
      owner_id?: string[]
      owner_user_id?: string[]
      template_id?: string[]
    }
  >
  export type Filter = Query['filter']
  export type Update = Id &
    Optional<Pick<Template, 'description' | 'name' | 'tokens_config' | 'custom_tokens'>>

  export const Singular = 'Template' as const
  export const Plural = 'Templates' as const
  export const MSG = {
    ACTION: {
      CREATE: `Create ${Singular}`,
      CREATE_SUBMIT: 'Create',
      CLONE: `Clone ${Singular}`,
      CLONE_SUBMIT: 'Clone',
      CREATE_SUCCESS: `${Singular} created.`,
      DELETE: `Delete ${Singular}`,
      DELETE_SUCCESS: `${Singular} deleted.`,
      EDIT_INFO: `Edit ${Singular} Info`,
      EDIT_INFO_SUBMIT: 'Update',
      EDIT_INFO_SUCCESS: `${Singular} ppdated.`,
      ORIGINAL_PDF: 'View Original PDF',
    },
    OVERVIEW: `Overview`,
    EDITOR: `Markup`,
    ERR: {
      NO_ID: 'Missing template_id',
      NOT_FOUND: `${Singular} not found.`,
    },
    LIST_EMPTY: `No ${Plural.toLowerCase()} found.`,
  } as const

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: Filter,
  ): { query: Query; sort: Sort | null; order: Order | null } {
    const filter: Filter = {}
    const global = searchParams.get('global')
    if (global) filter.global = global
    const pagination = parsePagination(searchParams)
    const order = parseOrder<Sort>(searchParams, {
      order: Order.desc,
      sort: 'name',
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }
  export interface Create {
    owner_id: string
    name: string
    file?: File
    description: string
  }

  export interface Clone {
    source_template_id: string
    target_name: string
  }

  export interface UpdateFile {
    template_id: string
    owner_id: string
    name: string
    file?: File
    description: string
  }

  export function getFilterFor(user: AdminUser): Filter {
    if (AdminUser.hasRoleOwner(user)) return { owner_user_id: [user.user_id] }
    // TODO: narrow to rent manager?
    if (AdminUser.hasRoleAdmin(user)) return {}
    throw new Error('Access to templates denied')
  }

  export const byId = (id: string) => (template: Template) => template.template_id === id
  export const pickId = (template: Template) => template.template_id
}

class TemplateBackend extends Client {
  list = async (query: Template.Query = {}, config?: PostConfig): Promise<Template[]> => {
    const { templates } = await this.post<
      Template.Query,
      { templates: Template[]; status: string }
    >('/owner/template/get', query, config)
    return templates
  }
  count = async (query: Template.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<Template.Query, { count: number; status: string }>(
      '/owner/template/count',
      query,
      config,
    )
    return count
  }
  paginatedList = createPaginatedList(this.list, this.count)

  listByIds = async (
    template_id?: string[],
    config?: PostConfig,
  ): Promise<Template[] | undefined> => {
    if (!template_id?.length) return undefined
    const templates = await this.list({ filter: { template_id } }, config)
    // make sure the order is preserved
    return template_id.map((id) => templates.find(Template.byId(id))).filter(Boolean) as Template[]
  }

  byId = async (template_id: string, config?: PostConfig): Promise<Template> => {
    const [template] = await this.list(
      { filter: { template_id: [template_id] }, pagination: { page: 1, page_size: 1 } },
      config,
    )
    if (!template) throw new Error(Template.MSG.ERR.NOT_FOUND)
    return template
  }

  downloadPDF = async (tid: string, config?: GetConfig): Promise<Blob> => {
    const file = await this.get<Blob, { tid: string }>(
      `/owner/template/pdf/download`,
      { tid },
      { ...config, responseType: 'blob' },
    )
    return file
  }

  create = async (data: Template.Create, config?: PostConfig): Promise<string> => {
    const { template_id } = await this.post<
      Template.Create,
      { template_id: string; status: string }
    >('/owner/template/pdf/upload', data, {
      timeout: UPLOAD_TIMEOUT,
      ...config,
      headers: { ...config?.headers, 'Content-Type': 'multipart/form-data' },
    })
    return template_id
  }

  clone = async (data: Template.Clone, config?: PostConfig): Promise<string> => {
    const { template_id } = await this.post<
      Template.Clone,
      { template_id: string; status: string }
    >('/owner/template/duplicate', data, config)
    return template_id
  }

  updateFile = async (data: Template.UpdateFile, config?: PostConfig): Promise<void> => {
    const formData = new FormData()
    formData.append('template_id', data.template_id)
    formData.append('description', data.description)
    formData.append('name', data.name)
    formData.append('owner_id', data.owner_id)
    data.file && formData.append('file', data.file)

    await this.post<FormData, { status: string }>('/owner/template/pdf/upload', formData, {
      timeout: UPLOAD_TIMEOUT,
      ...config,
      headers: { ...config?.headers, 'Content-Type': 'multipart/form-data' },
    })
  }

  update = async (
    { template_id, ...updates }: Template.Update,
    config?: PostConfig,
  ): Promise<void> => {
    type Req = Template.Id & {
      updates: Optional<Pick<Template, 'description' | 'name' | 'tokens_config'>>
    }

    await this.post<Req, { status: string }>(
      '/owner/template/update',
      { template_id, updates },
      config,
    )
  }

  remove = async (tid: string, config?: PostConfig): Promise<void> => {
    await this.delete('owner/template/delete', { ...config, params: { tid } })
  }
}

export const template = new TemplateBackend()
