import { Token } from 'api/template'
import { FC, ComponentProps } from 'react'
import { Card, Expandable } from 'ui'
import { cn } from 'utils'
import { ToolboxField } from './toolbox-field'
import styles from './toolbox.module.scss'
import { useEditorState } from '../state'

interface Props extends Omit<ComponentProps<typeof Card>, 'children'> {}

export const ToolboxFields: FC<Props> = ({ className, ...props }) => {
  const state = useEditorState()
  if (!state.fields) return null
  return (
    <Card {...props} className={cn(styles.toolbar, className)}>
      {state.fields.map(({ name, fields }, index) => (
        <Expandable.Context key={name} defaultOpened={index < 5}>
          <Expandable.Toggle className={styles.label}>{name}</Expandable.Toggle>
          <Expandable.Content className={styles.content}>
            <div className={styles.fields}>
              {fields.map((field) => (
                <ToolboxField
                  key={field.name}
                  field={field}
                  amount={state.tokens?.filter(Token.isTokenOfName(field.name)).length}
                />
              ))}
            </div>
          </Expandable.Content>
        </Expandable.Context>
      ))}
    </Card>
  )
}
