import { Radio, Token } from 'api/template'
import { CSSProperties, FC } from 'react'
import { CheckboxField, DateField, RadioField, TextField } from 'ui'
import { cn } from 'utils'
import styles from './page-field.module.scss'

interface Props {
  token: Token
  zoom: number
}
export const PageField: FC<Props> = ({ token, zoom }) => {
  return Token.isRadioToken(token) ? (
    <RadioField
      name={token.token_id}
      label={token.label}
      required={token.required}
      className={styles.radio}
    >
      {token.radio.map((radio) => (
        <label
          key={radio.radio_id}
          style={getStyle(radio, zoom)}
          className={cn(styles.pagefield, styles.option)}
          data-role={token.role.toLowerCase()}
          aria-required={token.required}
        >
          <input
            type="radio"
            name={token.token_id}
            value={radio.value}
            defaultChecked={radio.checked === '1'}
            className={styles.radioinput}
            id={radio.radio_id}
          />
          <span>{radio.value}</span>
        </label>
      ))}
    </RadioField>
  ) : (
    <div
      className={styles.wrapper}
      data-role={token.role.toLowerCase()}
      style={getStyle(token, zoom)}
    >
      {getSingleElement(token, zoom)}
    </div>
  )
}

function getStyle(item: Token | Radio, zoom = 1) {
  return {
    '--zoom': zoom,
    left: item.x * zoom,
    top: item.y * zoom,
    height: item.height * zoom,
    width: item.width * zoom,
  } as CSSProperties
}

function getSingleElement(token: Token, zoom: number) {
  switch (token.type) {
    case Token.Type.TEXT:
      return (
        <TextField
          id={token.token_id}
          name={token.token_id}
          label={token.label}
          defaultValue={(token as Token.Text).prefilled_text}
          required={token.required}
          className={cn(styles.pagefield, styles.field)}
        />
      )
    case Token.Type.DATE:
      return (
        <DateField
          id={token.token_id}
          name={token.token_id}
          label={token.label}
          required={token.required}
          className={cn(styles.pagefield, styles.field)}
        />
      )
    case Token.Type.CHECKBOX:
      return (
        <CheckboxField
          id={token.token_id}
          name={token.token_id}
          label={token.label}
          required={token.required}
          className={cn(styles.pagefield, styles.checkbox)}
        />
      )
    case Token.Type.SIGNATURE:
      return null
  }
}
