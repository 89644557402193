import { Token } from 'api/template'
import { FC, ComponentProps, CSSProperties } from 'react'
import { cn } from 'utils'
import styles from './page-item.module.scss'
import { RoleIcon } from '../icons/role-icon'

interface Props extends Omit<ComponentProps<'div'>, 'children'> {
  token: Token.Text
}
export const PageTokenText: FC<Props> = ({ className, token, style, ...props }) => {
  return (
    <div
      {...props}
      className={cn(styles.item, styles.text, className)}
      data-role={token.role.toLowerCase()}
      style={{ ...style, '--size': token.size, '--color': token.color } as CSSProperties}
      data-fixed={token._fixed ? 'true' : undefined}
    >
      <RoleIcon role={token.role} />
      <span className={styles.label}>{token.label}</span>
    </div>
  )
}
