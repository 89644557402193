import { Client, convertToServerData, PostConfig } from 'client'
import { addDays, toInputDatetime } from 'utils/date'
import { Application } from './application'
import { AdminApplication, adminApplication } from './application.admin'
import { Lease } from './lease'
import { AdminLease, lease as leaseApi } from './lease.admin'
import { Unit, unit as unitAPI } from './unit'
import { Token } from '../template'
import { ResultToken } from '../template/result-token'
import { Template, template as templateApi } from '../template/template.admin'
import { AdminUser, adminUser as adminUserApi } from '../user/user.admin'

export interface LeaseDraft {
  action: LeaseDraft.Action
  admin_values?: Record<string, string>
  api_values?: Record<string, string>
  auction_id?: string
  based_on_application_id?: string
  based_on_lease_id?: string
  created_at: string
  deposit: number
  disable_guarantors?: boolean
  draft_id: string
  end_at: string
  guarantors?: string[]
  offer_expire_at?: string
  override_tenants?: string[]
  owner_id: string
  owner_signer_id?: string
  rent: number
  start_at: string
  templates: string[]
  tenants: string[]
  updated_at?: string
  waive_deposit?: boolean
}

export namespace LeaseDraft {
  export type IdField = 'draft_id'
  export type Id = Pick<LeaseDraft, IdField>

  export const enum Action {
    APPLICATION = 'application',
    REGENERATE = 'regenerate',
    RENEW = 'renew',
  }

  export const MSG = {
    ACTION: {
      [Action.REGENERATE]: AdminLease.MSG.ACTION.CONTRACT_REGENERATE,
      [Action.RENEW]: AdminLease.MSG.ACTION.RENEW,
      [Action.APPLICATION]: AdminLease.MSG.ACTION.CREATE,
    },
    ERR: {
      NO_ID: 'Missing draft_id.',
      NOT_FOUND: 'Lease draft not found.',
      DOWNDLOAD_FAILED: 'Failed to load PDF.',
    },
    LIST_EMPTY: 'No lease drafts found.',
  } as const

  export type Create = Pick<
    LeaseDraft,
    | 'action'
    | 'auction_id'
    | 'based_on_application_id'
    | 'based_on_lease_id'
    | 'deposit'
    | 'disable_guarantors'
    | 'end_at'
    | 'offer_expire_at'
    | 'rent'
    | 'start_at'
    | 'templates'
    | 'waive_deposit'
  > & {
    override_tenants?: string[]
    owner_signer_id?: string
  }

  export interface Preview {
    draft_id: string
    snapshot_id: string
    fields: Token[]
    text_tokens: ResultToken[]
  }

  export const getDefaultValuesFromApplication = ({
    application,
    unit,
  }: {
    application: AdminApplication
    unit: Unit
  }): Create => {
    if (!application) throw new Error(AdminApplication.MSG.ERR.NOT_FOUND)
    if (!unit) throw new Error(AdminLease.MSG.ERR.NO_UNIT)
    const { start_at, end_at } = Application.getTerms(unit, application, unit.auction)
    return {
      action: Action.APPLICATION,
      auction_id: unit.auction?.auction_id,
      based_on_application_id: application.application_id,
      deposit: unit.deposit ? application.bid ?? unit.monthly_rent : 0,
      end_at,
      templates: [],
      rent: application.bid ?? unit.monthly_rent ?? 0,
      start_at,
    }
  }

  export const getDefaultValuesFromLease = ({
    lease,
    unit,
    renew,
  }: {
    lease: Lease
    unit: Unit
    renew?: boolean
  }): Create => {
    if (!lease) throw new Error(AdminLease.MSG.ERR.NOT_FOUND)
    const { start_at, end_at } = renew ? AdminLease.getDefaultRenewTerms(lease) : lease
    if (!start_at) throw new Error('missing start_at')
    if (!end_at) throw new Error('missing end_at')
    const data: LeaseDraft.Create = {
      action: renew ? Action.RENEW : Action.REGENERATE,
      based_on_lease_id: lease.lease_id,
      deposit: lease.deposit ?? (unit.deposit ? lease.monthly_rent ?? 0 : 0),
      end_at,
      templates: lease.templates ?? [],
      rent: lease.monthly_rent ?? 0,
      start_at,
    }
    if (renew) {
      const expires_at = addDays(new Date(), 1)
      expires_at.setSeconds(0)
      expires_at.setMilliseconds(0)
      data.offer_expire_at = toInputDatetime(expires_at)
    }
    return data
  }

  export const createTemplateFilter = (draft: LeaseDraft) => {
    const cosigners = draft.tenants?.length ?? 0
    const guarantors = draft.guarantors?.length ?? 0
    return (item: Template) =>
      (item.max_guarantors ?? 0) < guarantors || (item.max_tenants ?? 0) < cosigners
  }
}

export class LeaseDraftBackend extends Client {
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/application/post_lease_draft_new
   */
  create = async (data: LeaseDraft.Create, config?: PostConfig): Promise<string> => {
    const { draft_id } = await this.post<LeaseDraft.Create, { draft_id: string; status: string }>(
      '/lease/draft/new',
      convertToServerData(data, {
        date: ['start_at', 'end_at', 'offer_expire_at'],
      }),
      config,
    )
    return draft_id
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/lease/get_lease_draft_get */
  byId = async (did?: string, config?: PostConfig): Promise<LeaseDraft> => {
    if (!did) throw new Error(LeaseDraft.MSG.ERR.NO_ID)
    const { draft } = await this.get<{ draft: LeaseDraft }, { did: string }>(
      '/lease/draft/get',
      { did },
      config,
    )
    return draft
  }

  byApplicationId = async (
    application_id: string,
    config?: PostConfig,
  ): Promise<{
    draft: LeaseDraft
    application: AdminApplication
    unit: Unit
    templates: Template[]
    tenants: AdminUser.Brief[]
  }> => {
    let [draft, application] = await Promise.all([
      this.byId(application_id, config),
      adminApplication.byId(application_id, config),
    ])

    const unit_id = application.unit?.unit_id
    if (!unit_id) throw new Error(AdminLease.MSG.ERR.NO_UNIT)
    const unit = await unitAPI.byId(unit_id, config)
    if (!unit) throw new Error(AdminLease.MSG.ERR.NO_UNIT)
    if (!unit.owner_id) throw new Error(AdminLease.MSG.ERR.NO_UNIT_OWNER)

    if (
      !draft ||
      !draft.owner_signer_id ||
      !draft.owner_id ||
      (draft.auction_id ?? '') !== (unit.auction?.auction_id ?? '')
    ) {
      const draft_id = await this.create(
        LeaseDraft.getDefaultValuesFromApplication({ application, unit }),
        config,
      )
      draft = await this.byId(draft_id, config)
    }

    const [templates = [], tenants = []] = await Promise.all([
      draft.templates?.length ? templateApi.listByIds(draft.templates, config) : [],
      adminUserApi.list(
        { filter: { user_id: draft.tenants }, selector: AdminUser.Selector.brief },
        config,
      ),
    ])
    // get rid of invalid templates
    draft.templates = draft.templates?.filter((template_id) =>
      templates.some(Template.byId(template_id)),
    )
    return {
      templates,
      tenants,
      draft,
      unit,
      application,
    }
  }

  byLease = async (
    { lease_id, renew }: Lease.Id & { renew?: boolean },
    config?: PostConfig,
  ): Promise<{
    draft: LeaseDraft
    lease?: Lease
    templates: Template[]
    tenants: AdminUser.Brief[]
  }> => {
    const action = renew ? LeaseDraft.Action.RENEW : LeaseDraft.Action.REGENERATE
    let [draft, lease] = await Promise.all([
      this.byId(lease_id, config),
      leaseApi.byId(lease_id, config),
    ])
    if (
      !draft ||
      !draft.templates?.length ||
      !draft.owner_signer_id ||
      !draft.owner_id ||
      draft.action !== action
    ) {
      const unit_id = lease.unit_id
      if (!unit_id) throw new Error(AdminLease.MSG.ERR.NO_UNIT)
      const unit = await unitAPI.byId(unit_id, config)
      if (!unit) throw new Error(AdminLease.MSG.ERR.NO_UNIT)
      if (!unit.owner_id) throw new Error(AdminLease.MSG.ERR.NO_UNIT_OWNER)
      const draft_id = await this.create(
        LeaseDraft.getDefaultValuesFromLease({ lease, unit, renew }),
        config,
      )
      draft = await this.byId(draft_id, config)
    }
    const [templates = [], tenants = []] = await Promise.all([
      draft.templates?.length ? templateApi.listByIds(draft.templates, config) : [],
      adminUserApi.list(
        { filter: { user_id: draft.tenants }, selector: AdminUser.Selector.brief },
        config,
      ),
    ])
    if (templates?.length) {
      // get rid of invalid templates
      draft.templates = draft.templates?.filter((template_id) =>
        templates.some(Template.byId(template_id)),
      )
    }
    return {
      templates,
      tenants,
      draft,
      lease,
    }
  }

  /**
   * @see https://api-dev.rello.co/swagger/index.html#/lease/post_lease_preview
   */
  preview = async (draft_id?: string, config?: PostConfig) => {
    if (!draft_id) throw new Error(LeaseDraft.MSG.ERR.NO_ID)
    type Res = { preview: LeaseDraft.Preview; status: string }
    const { preview } = await this.post<LeaseDraft.Id, Res>('/lease/preview', { draft_id }, config)
    return preview
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/lease/get_lease_snapshot_download */
  downloadSnapshotById = async (sid: string, config?: PostConfig) => {
    const blob = await this.get<Blob, { sid: string }>(
      '/lease/snapshot/download',
      { sid },
      { responseType: 'blob', ...config },
    )
    if (!blob) throw new Error(LeaseDraft.MSG.ERR.DOWNDLOAD_FAILED)
    return blob
  }
}

export const leaseDraft = new LeaseDraftBackend()
