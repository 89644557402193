import { PdfViewer } from '@rello/pdf'
import { Token } from 'api/template'
import { convertToServerData } from 'client'
import { IconBack } from 'icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Form, Loader, SubmitButton } from 'ui'
import { cn } from 'utils'
import { pluralize } from 'utils/pluralize'
import { alertErrorMessage } from 'utils/toast'
import styles from './lease-builder.module.scss'
import { PageField } from './page-field'
import editorStyles from '../editor/admin-template-editor.module.scss'

type Data = Record<string, string>

interface Props<T> {
  pdf: Blob
  footerClassName?: string
  className?: string
  defaultValues?: Partial<Data>
  onSubmit: (values: Data) => Promise<T>
  redirect?: (result: T) => any
  fields: Token[]
  submitLabel: string
}

export function LeaseBuilder<T>({
  pdf,
  className,
  footerClassName,
  defaultValues,
  submitLabel,
  redirect,
  onSubmit,
  fields,
}: Props<T>) {
  const [activeTokenId, setActiveTokenId] = useState<string>()
  const [submitting, setSubmitting] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  const dateFieldIds = fields.filter((f) => f.type === Token.Type.DATE).map((f) => f.token_id)

  const isLast = activeTokenId && fields.at(-1)?.token_id === activeTokenId
  const isFirst = !activeTokenId || fields[0]?.token_id === activeTokenId
  useEffect(() => {
    const element = contentRef.current
    if (!element) return
    const listener = (event: FocusEvent) => {
      const name = getTokenId(event.target as HTMLElement)
      if (name) setActiveTokenId(name)
    }
    element.addEventListener('focusin', listener)
    return () => element.removeEventListener('focusin', listener)
  }, [])

  const next = useCallback(
    (inc = 1) => {
      const index = activeTokenId ? fields.findIndex(Token.byId(activeTokenId)) : -1
      const token_id = fields[index + inc]?.token_id
      const next = findElementByTokenId(token_id, contentRef.current)
      next?.focus()
    },
    [activeTokenId, fields],
  )

  const submit = useCallback(
    async (values: Data) => {
      setSubmitting(true)
      return await onSubmit(
        convertToServerData(values, {
          date: dateFieldIds,
        }),
      ).catch((e) => {
        setSubmitting(false)
        alertErrorMessage(e)
        throw e
      })
    },
    [dateFieldIds, onSubmit],
  )

  return (
    <>
      <Form
        defaultValues={defaultValues}
        onSubmit={submit}
        className={cn(styles.form, submitting && styles.submitting, className)}
        redirect={redirect}
      >
        <div className={cn(editorStyles.section, styles.workspace)} ref={contentRef}>
          <PdfViewer
            pdf={pdf}
            className={styles.document}
            onError={alertErrorMessage}
            renderPageContent={({ zoom, id }) => {
              if (!zoom) return <></>
              return (
                <>
                  {fields.filter(Token.byPageNumber(id)).map((token) => (
                    <PageField token={token} zoom={zoom} key={token.token_id} />
                  ))}
                </>
              )
            }}
          />
        </div>
        <footer className={cn(styles.footer, footerClassName)}>
          <div className={styles.actions}>
            {fields.length > 0 && (
              <>
                <p className={styles.info}>Please fill out {pluralize(fields.length, 'field')}</p>
                <Button
                  disabled={!!isFirst}
                  onClick={() => next(-1)}
                  theme="secondary"
                  className={styles.prev}
                >
                  <IconBack />
                </Button>
                <Button disabled={!!isLast} onClick={() => next(1)} theme="secondary">
                  Next
                </Button>
              </>
            )}
            <SubmitButton disableInvalid className={styles.submit}>
              {submitLabel}
            </SubmitButton>
          </div>
        </footer>
      </Form>
      {submitting && (
        <div className={styles.loader}>
          <Loader />
          <span>Generating Lease...</span>
        </div>
      )}
    </>
  )
}

const getTokenId = (element: HTMLElement) =>
  element && ['INPUT', 'SELECT'].includes(element.tagName) && element.hasAttribute('name')
    ? element.getAttribute('name')
    : undefined

const findElementByTokenId = (id?: string, container?: HTMLElement | null) => {
  if (!id || !container) return undefined
  return container.querySelector(`[name="${id}"]:is(input,select)`) as
    | HTMLSelectElement
    | HTMLInputElement
    | undefined
}
