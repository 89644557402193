import { Radio, Token } from 'api/template'
import { FC, ReactNode } from 'react'
import styles from './page-tokens.module.scss'
import { PageSelection, PageItem } from '../page-selection'
import { SelectablePageToken } from '../page-token/selectable-page-token'
import { useEditorApi, useEditorState } from '../state'
import { isSelected } from '../state/selectors'
interface Props {
  pageId: number
  zoom: number
}
export const PageTokens: FC<Props> = ({ pageId, zoom }) => {
  const state = useEditorState()
  const api = useEditorApi()
  const pageTokens = state.tokens.filter(Token.byPageNumber(pageId))

  const elements: ReactNode[] = []
  let bounds = { x1: Infinity, y1: Infinity, x2: 0, y2: 0 }
  let selectedAmount = 0

  pageTokens.forEach((token) => {
    if (Token.isRadioToken(token)) {
      token.radio.forEach((radio) => {
        const selected = isSelected(state.selection, token.token_id, radio.radio_id)
        if (selected) {
          bounds = extendBounds(bounds, radio)
          selectedAmount++
        }
        elements.push(
          <SelectablePageToken
            token={token}
            radio={radio}
            zoom={zoom}
            selected={selected}
            key={radio.radio_id}
          />,
        )
      })
    } else {
      const selected = isSelected(state.selection, token.token_id)
      if (selected) {
        bounds = extendBounds(bounds, token)
        selectedAmount++
      }
      elements.push(
        <SelectablePageToken token={token} zoom={zoom} selected={selected} key={token.token_id} />,
      )
    }
  })

  return (
    <>
      <div className={styles.container}>{elements}</div>
      {selectedAmount > 0 && (
        <PageSelection
          zoom={zoom}
          item={{
            page_number: pageId,
            x: bounds.x1,
            y: bounds.y1,
            width: bounds.x2 - bounds.x1,
            height: bounds.y2 - bounds.y1,
          }}
          onSelectionMove={(update: PageItem) => {
            api?.updateSelected((item: Token | Radio) => ({
              x: item.x + update.x,
              y: item.y + update.y,
            }))
          }}
          onSelectionResize={
            selectedAmount === 1
              ? (update: PageItem) => {
                  api?.updateSelected((item: Token | Radio) => ({
                    ...(update.width && { width: update.width }),
                    ...(update.height && { height: update.height }),
                    ...(update.x && { x: item.x + update.x }),
                    ...(update.y && { y: item.y + update.y }),
                  }))
                }
              : undefined
          }
        />
      )}
    </>
  )
}
type Item = { x: number; y: number; width: number; height: number }

const extendBounds = (bounds: { x1: number; x2: number; y1: number; y2: number }, item: Item) => {
  return {
    x1: Math.min(bounds.x1, item.x),
    y1: Math.min(bounds.y1, item.y),
    x2: Math.max(bounds.x2, item.x + item.width),
    y2: Math.max(bounds.y2, item.y + item.height),
  }
}
