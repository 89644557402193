import { Radio } from './radio'
import { TemplateCustomToken } from './template-custom-token.admin'
import { Template } from './template.admin'

export interface Token {
  _index: number
  _fixed?: Partial<Token>
  token_id: string
  x: number
  y: number
  name: string
  role: Token.Role
  width: number
  height: number
  required: boolean
  page_number: number
  validator_id: string
  custom_defined_option: boolean
  lock_to_sign_date: boolean
  color?: string // TODO: exact name?
  size?: number // TODO: exact name?
  dependency?: Token.TokenDependency
  conditional?: boolean
  label: string
  description?: string
  type: Token.Type
  radio?: Radio[]
}

export namespace Token {
  export type IdField = 'token_id'
  export type Id = Pick<Token, IdField>

  export const enum Type {
    TEXT = 'text',
    DATE = 'date',
    CHECKBOX = 'checkbox',
    SIGNATURE = 'signature',
    RADIO = 'radiobutton',
  }
  export const enum Role {
    T1 = 'Tenant1',
    T2 = 'Tenant2',
    T3 = 'Tenant3',
    T4 = 'Tenant4',
    T5 = 'Tenant5',
    T6 = 'Tenant6',
    G1 = 'Guarantor1',
    G2 = 'Guarantor2',
    G3 = 'Guarantor3',
    G4 = 'Guarantor4',
    G5 = 'Guarantor5',
    G6 = 'Guarantor6',
    OWNER = 'Owner',
    ADMIN = 'Admin',
    API = 'API',
  }

  export const isRole = (role: string): role is Role =>
    [
      Role.T1,
      Role.T2,
      Role.T3,
      Role.T4,
      Role.T5,
      Role.T6,
      Role.G1,
      Role.G2,
      Role.G3,
      Role.G4,
      Role.G5,
      Role.G6,
      Role.OWNER,
      Role.ADMIN,
      Role.API,
    ].includes(role as Role)

  export const enum SignatureType {
    draw = 'draw',
    upload = 'upload',
    type = 'type',
  }

  export const enum TokenDependencyOperator {
    TRUE = 'is_true',
    EQUAL = 'equals',
  }

  export interface TokenDependency {
    parent: { token_id: string; radio_id?: string }[]
    operator: TokenDependencyOperator
  }

  export interface Text extends Token {
    type: Token.Type.TEXT
    prefilled_text?: string
  }
  export interface Date extends Token {
    type: Token.Type.DATE
    //TODO: format
  }
  export interface Checkbox extends Token {
    type: Token.Type.CHECKBOX
  }
  export interface Signature extends Token {
    type: Token.Type.SIGNATURE
    allowed_types?: Token.SignatureType[]
  }

  export interface TokenRadio extends Token {
    type: Token.Type.RADIO
    radio: Radio[]
  }

  export type TokenWithSingleItem = Text | Date | Signature | Checkbox

  type RawTokenWithSingleItem = Omit<TokenWithSingleItem, '_index' | '_fixed'>
  export type RawRadio = Omit<Radio, '_fixed' | '_radioIndex' | '_index'>
  type RawRadioToken = Omit<TokenRadio, '_index' | 'fixed' | 'radio'> & { radio: RawRadio[] }

  export type Raw = RawTokenWithSingleItem | RawRadioToken

  export const sortTokens = <T extends Pick<Token, 'page_number' | 'x' | 'y'>>(tokens: T[]): T[] =>
    [...tokens].sort((a, b) => a.page_number - b.page_number || a.y - b.y || a.x - b.x)

  export const init = (tokens: (Token | Raw)[], template: Template.Id): Token[] => {
    if (!tokens?.length) return []
    return sortTokens(tokens)
      .map((t, _index) => {
        const role = isRole(t.role) ? t.role : Role.API
        if (role === Role.API && !t.name) return undefined

        const token = {
          ...t,
          token_id: t.token_id ?? createId(template),
          label: t.label ?? nameToLabel(t.name),
          type: t.type,
          role,
          _index,
        } as Token

        if (isRadioToken(token)) {
          return (token.radio?.length ?? 0) < 2 ? undefined : initTokenRadios(token)
        }
        return token
      })
      .filter(Boolean) as Token[]
  }

  export const initTokenRadios = (token: TokenRadio): TokenRadio => {
    token.radio = token.radio?.map((r, _radioIndex) => ({
      ...r,
      _index: token._index,
      _radioIndex,
      radio_id: r.radio_id ?? createRadioId(token),
    })) as Radio[]
    return token
  }

  interface CreateFieldOptions {
    x: number
    y: number
    name?: string
    page_number: number
    _index: number
  }
  const RADIO_SIZE = 17
  const RADIO_DIMENSION = {
    width: RADIO_SIZE,
    height: RADIO_SIZE,
  }
  const TEXT_DIMENSION = {
    width: 100,
    height: 14,
  }

  export const byId = (id: string) => (token: Id) => token.token_id === id
  export const byRole = (role: Role) => (token: Token | Token.Raw) => token.role === role

  export const byRadioId = (id: string) => (radio: Pick<Radio, 'radio_id'>) => radio.radio_id === id

  export const createRadio = (
    data: Pick<Radio, 'value' | 'x' | 'y' | 'page_number' | '_radioIndex'>,
    tokenRadio: Pick<Token, 'token_id' | '_index'>,
  ): Radio => {
    return {
      ...RADIO_DIMENSION,
      checked: '0',
      created: 0,
      size: Math.floor(RADIO_SIZE / 2),
      radio_id: createRadioId(tokenRadio),
      ...data,
      _index: tokenRadio._index,
    }
  }
  export const createToken = (
    options: CreateFieldOptions,
    toolField: TemplateCustomToken.Field,
    template: Template.Id,
  ): Token => {
    const base: Token = {
      ...([Type.CHECKBOX, Type.RADIO].includes(toolField.type) ? RADIO_DIMENSION : TEXT_DIMENSION),
      ...toolField.config,
      label: toolField.label,
      name: toolField.name,
      ...options,
      token_id: createId(template),
      type: toolField.type,
    }
    if (toolField.type !== Type.RADIO) {
      return base
    }
    return {
      ...base,
      type: toolField.type,
      radio: [
        createRadio(
          {
            value: 'Yes',
            _radioIndex: 0,
            ...options,
          },
          base,
        ),
        createRadio(
          {
            value: 'No',
            _radioIndex: 1,
            ...options,
            y: options.y + 100,
          },
          base,
        ),
      ],
    }
  }

  const nameToLabel = (_name: string) => {
    let name = _name.replace(/-.*/, '')
    if (name.startsWith('owner_')) {
      name = name.replace(/^owner_/, "Owner's ")
    } else if (name.startsWith('tenant')) {
      name = name.replace(/^(tenant)(\d)_/, `T$2's `)
    } else if (name.startsWith('guarantor')) {
      name = name.replace(/^(guarantor)(\d)_/, `G$2's `)
    }
    return name.replace('_', ' ')
  }

  const shortId = (id: string) => id.replace(/-.*/, '')
  export const createId = (t: Template.Id) =>
    `${shortId(t.template_id)}:${window.crypto.randomUUID()}`
  export const createRadioId = (token: Id) =>
    `${shortId(token.token_id)}:${window.crypto.randomUUID()}`

  export const isRadioToken = (t?: Token | Radio): t is TokenRadio =>
    (t as Token)?.type === Type.RADIO
  export const isText = (t: Token | Radio): t is Text => (t as Token).type === Type.TEXT
  export const isDate = (t: Token | Radio): t is Date => (t as Token).type === Type.DATE
  export const isCheckbox = (t: Token | Radio): t is Checkbox => (t as Token).type === Type.CHECKBOX
  export const isSignature = (t: Token | Radio): t is Signature =>
    (t as Token).type === Type.SIGNATURE

  export const isTokenStatic = (t?: Pick<Token, 'role'>) => t?.role === Role.API

  export const byPageNumber = (page: number) => (t: Pick<Token, 'page_number'>) =>
    t.page_number === page

  export const DIVIDER = '-' as const
  export const isTokenOfName = (name: string) => (token?: Token) => {
    if (!token || !name) return false
    return token.name === name || getBaseName(token) === name
  }
  const getBaseName = (token?: Pick<Token, 'name'>) =>
    token?.name ? token.name.replace(/-.*$/, '') : undefined
}
