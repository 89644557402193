import { DeleteConfig, GetConfig, PostConfig, convertToServerData, dateToServerDate } from 'client'
import { createDiff } from 'utils/diff'
import { Order, createPaginatedList, parseOrder, parsePagination } from 'utils/list'
import { pluralize } from 'utils/pluralize'
import { PickType } from 'utils/type-utils'
import { Unit, UnitBackend } from './unit'
import { AdminUser } from '../user/user.admin'

export namespace AdminUnit {
  export interface Validity {
    is_ready: boolean
    reason: string
    status: string
  }
  export type Create = Partial<
    Pick<
      Unit,
      | 'application_fee_amount'
      | 'available_at'
      | 'baths'
      | 'beds'
      | 'city'
      | 'country'
      | 'credit_check_required'
      | 'deposit'
      | 'description'
      | 'district'
      | 'dishwasher'
      | 'enable_monthly_payments'
      | 'extend'
      | 'floor'
      | 'hidden'
      | 'is_commercial'
      | 'latitude'
      | 'longitude'
      | 'monthly_rent'
      | 'name'
      | 'owner_id'
      | 'pets_allowed'
      | 'preferred_lease_term'
      | 'property_id'
      | 'rooms'
      | 'sqft'
      | 'state'
      | 'street'
      | 'unit_number'
      | 'vacant'
      | 'virtual_doorman'
      | 'wd_in_unit'
      | 'zip'
    >
  > & {
    // disable?: boolean
    property_id?: string
  }

  export type Update = Partial<Create>

  export const BOOLEAN_FIELDS: (keyof PickType<Update, boolean>)[] = [
    'credit_check_required',
    'deposit',
    'dishwasher',
    'enable_monthly_payments',
    'hidden',
    'vacant',
    'is_commercial',
    'pets_allowed',
    'virtual_doorman',
    'wd_in_unit',
  ]
  export const NUMBER_FIELDS: (keyof PickType<Update, number>)[] = [
    'application_fee_amount',
    'baths',
    'beds',
    'floor',
    'latitude',
    'longitude',
    'monthly_rent',
    'rooms',
    'sqft',
  ]
  export const DATE_FIELDS: (keyof PickType<Update, string> & 'available_at')[] = ['available_at']

  export const STRING_FIELDS: Exclude<
    keyof PickType<Update, string>,
    (typeof DATE_FIELDS)[number]
  >[] = [
    'city',
    'country',
    'description',
    'district',
    'name',
    'state',
    'street',
    'unit_number',
    'zip',
    'owner_id',
    'property_id',
  ]

  export const getDiff = createDiff<Unit, AdminUnit.Update>({
    date: DATE_FIELDS,
    boolean: BOOLEAN_FIELDS,
    number: NUMBER_FIELDS,
    string: STRING_FIELDS,
    extend: (extend?: Unit.Extend) => getExtendHash(extend),
    preferred_lease_term: (preferred_lease_term?: Unit.PreferredTerm) =>
      getPreferredLeaseTermString({ preferred_lease_term }) ?? '',
  })

  export function getFilterFor(user: AdminUser): Unit.Filter {
    if (AdminUser.hasRoleOwner(user)) return { owner_user_id: [user.user_id] }
    if (AdminUser.hasRoleAgent(user)) return { agent_id: [user.user_id] }
    return {}
  }

  export interface AdminPublicFilter {
    global?: string
    no_vacant?: boolean
    no_hidden?: boolean
    property_id?: string[]
    owner_id?: string[]
  }

  export function toSearchParams(
    filter: Unit.Filter,
    searchParams = new URLSearchParams(),
  ): URLSearchParams {
    //TODO: complete filters
    if (!filter) return searchParams
    filter.hidden === false && searchParams.set('no_hidden', 'true')
    filter.agent_id?.length && searchParams.set('agent_id', filter.agent_id.join(','))
    filter.property_id?.length && searchParams.set('property_id', filter.property_id.join(','))
    filter.owner_id?.length && searchParams.set('owner_id', filter.owner_id.join(','))
    return searchParams
  }

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: Unit.Filter,
  ): {
    query: Unit.Query
    sort: Unit.Sort | null
    order: Order | null
    publicFilter: AdminPublicFilter
  } {
    const filter: Unit.Filter = {}
    const publicFilter: AdminPublicFilter = {}

    const global = searchParams.get('global') as string
    if (global) {
      filter.global = global
      publicFilter.global = global
    }
    const propertyIds = searchParams.get('property_id')
    const property_id = propertyIds ? propertyIds.split(',') : []
    if (property_id.length) {
      filter.property_id = property_id
      publicFilter.property_id = property_id
    }
    const ownerIds = searchParams.get('owner_id')
    const owner_id = ownerIds ? ownerIds.split(',') : []
    if (owner_id.length) {
      filter.owner_id = owner_id
      publicFilter.owner_id = owner_id
    }
    const no_hidden = searchParams.get('no_hidden') === 'true'
    if (no_hidden) {
      filter.hidden = false
      publicFilter.no_hidden = true
    }
    const no_vacant = searchParams.get('no_vacant') === 'true'
    if (no_vacant) {
      filter.vacant = false
      publicFilter.no_vacant = true
    }
    const pagination = parsePagination(searchParams)
    const order = parseOrder<Unit.Sort>(searchParams, {
      order: Order.desc,
      sort: 'created_at',
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
      publicFilter,
    }
  }

  const getExtendHash = (extend?: Unit.Extend) =>
    extend ? [extend.ical, extend.nightly_rent].join('|') : ''

  export function getPreferredLeaseTermString(unit: Pick<Unit, 'preferred_lease_term'>) {
    if (!unit.preferred_lease_term) return null
    const entries = Object.entries(unit.preferred_lease_term).filter(([_, value]) => value > 0)
    if (!entries.length) return null
    const [period, value] = entries[0] as [Unit.TermPeriod, number]
    return pluralize(value, period.slice(0, -1))
  }

  type SerializedUnit = Partial<
    Record<keyof Unit | `extend.${keyof Unit.Extend}`, string | number | boolean>
  >

  export const getFriendlyUpdate = (unit: Update): SerializedUnit => {
    const { preferred_lease_term, extend, ..._result } = unit
    let result: SerializedUnit = Object.fromEntries(
      Object.entries(_result).map(([key, value]) => [
        key,
        typeof value === 'boolean' ? (value ? 'true' : 'false') : value,
      ]),
    ) as SerializedUnit
    if (preferred_lease_term) {
      result.preferred_lease_term = getPreferredLeaseTermString({ preferred_lease_term }) ?? ''
    }
    if (extend) {
      result['extend.ical'] = extend.ical ?? ''
      result['extend.nightly_rent'] = extend.nightly_rent ?? ''
    }
    return result
  }

  export const isShareAvailable = (unit: Unit) => !!unit.monthly_rent
}

function toServerData(data: AdminUnit.Create) {
  return convertToServerData(data, {
    string: AdminUnit.STRING_FIELDS,
    date: AdminUnit.DATE_FIELDS,
    number: AdminUnit.NUMBER_FIELDS,
  })
}

class AdminUnitBackend extends UnitBackend {
  create = async (data: AdminUnit.Create, config?: PostConfig): Promise<Unit.Id> => {
    const { unit_id } = await this.post<
      { values: AdminUnit.Create },
      { status: string; unit_id: string }
    >(
      '/unit/new',
      {
        values: {
          ...data,
          ...(data.available_at && {
            available_at: dateToServerDate(data.available_at),
          }),
        },
      },
      config,
    )
    return { unit_id }
  }

  update = async (id: string, data: AdminUnit.Update, config?: PostConfig): Promise<Unit> => {
    return await this.post<Partial<{ values: Partial<AdminUnit.Create> & Unit.Id }>, Unit>(
      '/unit/update',
      {
        values: { ...toServerData(data as AdminUnit.Create), unit_id: id },
      },
      {
        ...config,
        params: { pid: id },
      },
    )
  }

  remove = async (id?: string, config?: DeleteConfig): Promise<void> => {
    if (!id) throw new Error('Missing unit id')
    await this.delete('/unit/delete', {
      ...config,
      params: { ...config?.params, pid: id },
    })
  }

  addAgent = async (
    data: { agent_user_id: string } & Unit.Id,
    config?: PostConfig,
  ): Promise<void> => {
    return await this.post('/unit/agent/add', data, config)
  }

  removeAgent = async (
    data: { agent_user_id: string } & Unit.Id,
    config?: PostConfig,
  ): Promise<void> => {
    return await this.post('/unit/agent/remove', data, config)
  }

  importRM = async (data: Unit.ImportRM, config?: PostConfig): Promise<Unit.Id> => {
    const { unit_id } = await this.post<Unit.ImportRM, { status: string } & Unit.Id>(
      '/unit/import/rentmanager',
      data,
      config,
    )
    return { unit_id }
  }

  importURL = async (data: Unit.ImportURL, config?: PostConfig): Promise<Unit.Id> => {
    const { unit_id } = await this.post<Unit.ImportURL, { status: string } & Unit.Id>(
      '/unit/import/streeteasy',
      data,
      config,
    )
    return { unit_id }
  }

  validateById = async (id: string, config?: GetConfig): Promise<AdminUnit.Validity> => {
    return await this.get<AdminUnit.Validity, { id: string }>('/unit/ready', { id }, config)
  }

  findLocation = async ({ unit_id }: Unit.Id, config?: PostConfig): Promise<void> => {
    await this.post<Unit.Id, { status: string }>('/unit/find/location', { unit_id }, config)
  }

  byIdForUser = async (
    unit_id: string,
    user: AdminUser,
    config?: PostConfig,
  ): Promise<Unit | null> => {
    const [unit] = await this.list(
      { filter: { ...AdminUnit.getFilterFor(user), unit_id: [unit_id] } },
      config,
    )
    return unit ?? null
  }

  paginatedList = createPaginatedList(this.list, this.count)
}

export const unit = new AdminUnitBackend()
