import { TemplateCustomToken } from 'api/admin'
import { Token } from 'api/template'
import { FC, ComponentProps, useCallback, useState } from 'react'
import {
  Button,
  Card,
  CheckboxField,
  Form,
  HiddenField,
  NumberField,
  Select,
  TextAreaField,
  TextField,
} from 'ui'
import { cn } from 'utils'
import { ItemFixes } from './item-fixes'
import { ToolboxRadioProperties } from './toolbox-radio-properties'
import styles from './toolbox-token-properties.module.scss'
import { RoleIcon } from '../icons/role-icon'
import { useEditorApi } from '../state'

interface Props extends Omit<ComponentProps<typeof Card>, 'children'> {
  token: Token
}

interface Data {
  required: boolean
  label?: string
  description?: string
  wet?: boolean
  role: Token.Role
  prefilled_text?: string
  _dependency_token_id: string
  _dependency_radio_id?: string
  x: number
  y: number
  width: number
  height: number
  _index: number
}

export const ToolboxTokenProperties: FC<Props> = ({ className, token, ...props }) => {
  const api = useEditorApi()
  const addRadio = useCallback(() => {
    Token.isRadioToken(token) && api?.addRadioByIndex?.(token.token_id)
  }, [api, token])

  const [defaultValues] = useState(toFormData(token))

  const update = useCallback(
    (values: Record<string, any>) => {
      const changedEntries = Object.entries(values).filter(
        ([name, value]) => (defaultValues[name as keyof Data] ?? null) !== (value ?? null),
      )
      if (!changedEntries.length) return

      const data = changedEntries.reduce(
        (data, [name, value]) => ({
          ...data,
          ...(fromData(name, value, { ...token, ...data } as Token) as Token),
        }),
        {} as Partial<Token>,
      )
      api?.updateToken?.(data, token.token_id)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token],
  )

  if (!token) return null

  return (
    <Card
      {...props}
      className={cn(styles.toolbar, className)}
      data-role={token.role.toLowerCase()}
      onKeyDown={stopPropagation}
    >
      <div>
        <div className={styles.title}>
          {!Token.isTokenStatic(token) && <RoleIcon role={token.role} />}

          <span>{token.label}</span>
          {typeof token._index === 'number' && (
            <span className={styles.other}>#{token._index}</span>
          )}
        </div>

        {Token.isTokenStatic(token) && (
          <>
            <span className={styles.other}>{token.name}</span>
          </>
        )}
      </div>

      {token._fixed && <ItemFixes token={token} key={token.name} />}

      <Form onSubmit={noop} onChange={update} className={styles.form} defaultValues={defaultValues}>
        <HiddenField name="_index" />

        {!Token.isTokenStatic(token) && (
          <>
            <TextField label="Label" name="label" className={styles.field} />
            <Select
              label="Role"
              name="role"
              options={TemplateCustomToken.ROLE_OPTIONS}
              className={styles.field}
            />
            {Token.isText(token) && (
              <TextField label="Prefilled Text" name="prefilled_text" className={styles.field} />
            )}
            <TextAreaField label="Description" name="description" className={styles.field} />
            <CheckboxField label="Required?" name="required" className={styles.checkbox} />
            {Token.isSignature(token) && (
              <CheckboxField label="Wet signature only" name="wet" className={styles.checkbox} />
            )}
          </>
        )}
        {!Token.isRadioToken(token) && (
          <fieldset className={styles.position}>
            <NumberField name="x" label="X" className={styles.field} step={1} min={0} />
            <NumberField name="y" label="Y" className={styles.field} step={1} min={0} />
            <NumberField name="width" label="W" className={styles.field} step={1} min={10} />
            <NumberField name="height" label="H" className={styles.field} step={1} min={10} />
          </fieldset>
        )}
      </Form>

      {!Token.isTokenStatic(token) && Token.isRadioToken(token) && (
        <>
          {token.radio.map((radio, index) => (
            <ToolboxRadioProperties
              key={index}
              radio={radio}
              token={token}
              className={styles.field}
            />
          ))}
          <footer>
            <Button theme="secondary" onClick={addRadio} className={styles.tiny}>
              Add
            </Button>
          </footer>
        </>
      )}
    </Card>
  )
}

const stopPropagation = (event: React.KeyboardEvent) => {
  event.stopPropagation()
}

const noop = () => {}

const toFormData = (token: Token) => {
  return {
    _index: token._index,
    label: token.label,
    role: token.role,
    required: token.required,
    description: token.description,
    x: Math.round(token.x),
    y: Math.round(token.y),
    width: Math.round(token.width),
    height: Math.round(token.height),
    _dependency_token_id: token.dependency?.parent[0]?.token_id,
    _dependency_radio_id: token.dependency?.parent[0]?.radio_id,
    ...(Token.isSignature(token) && { wet: !!token.allowed_types }),
    ...(Token.isText(token) && { prefilled_text: token.prefilled_text }),
  }
}
const fromData = (name: string, value: any, token: Token) => {
  switch (name) {
    case 'wet':
      return {
        allowed_types: value ? [Token.SignatureType.draw, Token.SignatureType.upload] : null,
      }
    case '_dependency_token_id':
      return {
        dependency: {
          operator: Token.TokenDependencyOperator.TRUE,
          parent: [
            {
              ...token.dependency?.parent[0],
              token_id: value ?? null,
            },
          ],
        },
        conditional: !!value,
      }
    case '_dependency_radio_id':
      return {
        dependency: {
          operator: Token.TokenDependencyOperator.EQUAL,
          parent: [
            {
              ...token.dependency?.parent[0],
              radio_id: value ?? null,
            },
          ],
        },
      }
  }
  return { [name]: value }
}

// const getDependencyOptions = (state: EditorState, token: Token) => {
//   return state.tokens
//     .map(({ token_id, page_number, role, label }) =>
//       token.token_id === token_id
//         ? undefined
//         : {
//             value: token_id,
//             label: `Page ${page_number + 1}. ${label} (${role})`,
//           },
//     )
//     .filter(Boolean) as { value: string; label: string; disabled?: boolean }[]
// }

// const getRadioOptions = (token: TokenRadio) => {
//   return token.radio.map((radio) => ({
//     value: radio.radio_id,
//     label: radio.value,
//   }))
// }
