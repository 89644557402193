import { Radio, Token } from 'api/template'
import { FC, ComponentProps, CSSProperties } from 'react'
import { Tooltip } from 'ui'
import { cn } from 'utils'
import styles from './page-item.module.scss'
import { PageRadioOption } from './page-radio-option'
import { PageTokenCheckbox } from './page-token-checkbox'
import { PageTokenDate } from './page-token-date'
import { PageTokenSignature } from './page-token-signature'
import { PageTokenText } from './page-token-text'

type Item = Token | Radio
type Props = Omit<ComponentProps<'div'>, 'children'> & {
  zoom: number
  showTooltip?: boolean
  token: Token
  radio?: Radio
}

export const PageToken: FC<Props> = ({ className, token, radio, zoom, showTooltip, ...props }) => {
  if (Token.isRadioToken(token) && !radio) return null
  const itemProps = {
    ...props,
    style: getStyle(radio ?? token, zoom),
    className: cn(styles.item, className),
    'data-role': token.role.toLowerCase(),
    'data-token-id': token.token_id,
    'data-radio-id': radio?.radio_id,
  }

  if (showTooltip) {
    itemProps['data-tooltip-id'] = Tooltip.ID
    itemProps['data-tooltip-content'] = `${token.label ?? ''}\n${token.description ?? ''}\n${
      token.role
    }`
  }

  return Token.isSignature(token) ? (
    <PageTokenSignature token={token} {...itemProps} />
  ) : Token.isText(token) ? (
    <PageTokenText token={token} {...itemProps} />
  ) : Token.isDate(token) ? (
    <PageTokenDate token={token} {...itemProps} />
  ) : Token.isCheckbox(token) ? (
    <PageTokenCheckbox token={token} {...itemProps} />
  ) : Token.isRadioToken(token) ? (
    <PageRadioOption token={token} radio={radio!} {...itemProps} />
  ) : null
}

function getStyle(item: Item, zoom = 1) {
  return {
    '--zoom': zoom,
    left: item.x * zoom,
    top: item.y * zoom,
    height: item.height * zoom,
    width: item.width * zoom,
  } as CSSProperties
}
