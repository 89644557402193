import { Template } from 'api/admin'
import { Token } from 'api/template'
import { forwardRef, useImperativeHandle, useReducer } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { cn } from 'utils'
import styles from './admin-template-editor.module.scss'
import { KeyboardControl } from '../keyboard-control'
import { SelectionContext } from '../selection'
import {
  EditorApiProvider,
  StateProvider,
  editorReducer,
  getInitialState,
  useEditorActions,
  getRawTokens,
} from '../state'
import { ToolboxFields, ToolboxActions } from '../toolbox-fields'
import { ToolboxSelection } from '../toolbox-selection'
import { Workspace } from '../workspace'

interface Props {
  className?: string
  template: Template
  pdf: Blob
  onSave: (tokens: Token.Raw[]) => Promise<void>
}

export interface AdminTemplateEditorApi {
  getTokens: () => Token.Raw[]
}

export const AdminTemplateEditor = forwardRef<AdminTemplateEditorApi, Props>(
  ({ pdf, className, template, onSave }, ref) => {
    const [state, dispach] = useReducer(editorReducer, template, getInitialState)
    const actions = useEditorActions(dispach)
    useImperativeHandle(ref, () => ({ getTokens: () => getRawTokens(state) }), [state])

    return (
      <EditorApiProvider value={actions}>
        <StateProvider value={state}>
          <SelectionContext.Provider value={[state.selection, actions.select]}>
            <DndProvider backend={HTML5Backend}>
              <KeyboardControl className={cn(styles.section, className)}>
                <Workspace pdf={pdf} />
                <aside className={styles.aside}>
                  <ToolboxActions onSave={onSave} />
                  <ToolboxFields />
                  <ToolboxSelection />
                </aside>
              </KeyboardControl>
            </DndProvider>
          </SelectionContext.Provider>
        </StateProvider>
      </EditorApiProvider>
    )
  },
)

AdminTemplateEditor.displayName = 'AdminTemplateEditor'
