import { Radio, Token } from 'api/template'
import { IconClose } from 'icons'
import { FC, useCallback } from 'react'
import { CheckboxField, Form, IconButton, NumberField, TextField } from 'ui'
import { cn } from 'utils'
import { ItemFixes } from './item-fixes'
import styles from './toolbox-token-properties.module.scss'
import { useSelected } from '../selection'
import { useEditorApi } from '../state'

interface Props {
  className?: string
  token: Token.TokenRadio
  radio: Radio
}
export const ToolboxRadioProperties: FC<Props> = ({ className, token, radio, ...props }) => {
  const api = useEditorApi()
  const [selected] = useSelected({ token_id: token.token_id, radio_id: radio.radio_id })

  const handleDelete = useCallback(() => {
    api?.deleteRadioById(token.token_id, radio.radio_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token.token_id, radio.radio_id])

  const update = useCallback(
    ({ checked, ...data }: Partial<Data>) => {
      api?.updateRadioById(
        { ...data, checked: checked ? '1' : '0' },
        token.token_id,
        radio.radio_id,
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token.token_id, radio.radio_id],
  )

  return (
    <Form
      {...props}
      id={radio.radio_id}
      className={cn(styles.form, styles.option, selected && styles.selected, className)}
      onChange={update}
      onSubmit={update}
    >
      <header className={styles.header}>
        <span className={styles.title}>Option {radio._radioIndex + 1}</span>
        {token.radio.length > 2 && (
          <IconButton title="Delete" className={styles.delete} onClick={handleDelete}>
            <IconClose />
          </IconButton>
        )}
      </header>
      {radio._fixed && <ItemFixes token={token} radio={radio} />}
      <TextField
        label="Value"
        name="value"
        defaultValue={radio.value}
        className={styles.field}
        required
      />
      <CheckboxField
        label="Checked?"
        name="checked"
        defaultValue={radio.checked === '1'}
        className={styles.checkbox}
      />
      <fieldset className={styles.position}>
        <NumberField
          name="x"
          label="X"
          className={styles.field}
          step={1}
          min={0}
          defaultValue={Math.round(radio.x)}
        />
        <NumberField
          name="y"
          label="Y"
          className={styles.field}
          step={1}
          min={0}
          defaultValue={Math.round(radio.y)}
        />
        <NumberField
          name="width"
          label="W"
          className={styles.field}
          step={1}
          min={10}
          defaultValue={Math.round(radio.width)}
        />
        <NumberField
          name="height"
          label="H"
          className={styles.field}
          step={1}
          min={10}
          defaultValue={Math.round(radio.height)}
        />
      </fieldset>
    </Form>
  )
}

interface Data {
  x: number
  y: number
  width: number
  height: number
  checked: boolean
  value: string
}
