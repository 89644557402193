import { TemplateCustomToken } from 'api/admin'
import { FC, ComponentProps, useRef } from 'react'
import { DragSourceMonitor, useDrag } from 'react-dnd'
import { Badge, Tooltip } from 'ui'
import { cn } from 'utils'
import styles from './toolbox-field.module.scss'
import { RoleIcon } from '../icons/role-icon'
import { WorkspacePageDropResult } from '../page'
import { useEditorApi, DraggableType, DroppableItem } from '../state'

interface Props extends ComponentProps<'div'> {
  field: TemplateCustomToken.Field
  amount?: number
}

type DraggableItem = { field: TemplateCustomToken.Field } & DroppableItem

export const ToolboxField: FC<Props> = ({ children, className, field, amount = 0, ...props }) => {
  const wrapperRef = useRef<HTMLDivElement>()
  const api = useEditorApi()

  const [{ isDragging }, dragRef] = useDrag<DraggableItem, WorkspacePageDropResult, CollectedProps>(
    () => ({
      type: DraggableType.TOOLBOX,

      item: (monitor) => {
        const el = wrapperRef.current
        if (!el) return null
        const { width, height, top, left } = el.getBoundingClientRect() ?? { width: 0, height: 0 }
        const offset = monitor.getInitialClientOffset()
        if (!offset) return null
        const item = { field, width, height, mouseTop: offset.y - top, mouseLeft: offset.x - left }
        return item
      },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
      options: {
        dropEffect: 'copy',
      },

      end(draggedItem, monitor) {
        if (!monitor.didDrop()) return
        const result = monitor.getDropResult()
        if (!result) return

        api?.create({ result, field })
      },
    }),
    [field],
  )

  return (
    <div
      {...props}
      className={cn(styles.div, isDragging && styles.drag, className)}
      data-role={field.config.role.toLowerCase()}
      data-tooltip-id={Tooltip.ID}
      data-tooltip-content={field.description}
      ref={(el) => {
        if (el) wrapperRef.current = el
        dragRef(el)
      }}
    >
      <RoleIcon role={field.config.role} />
      <span>{field.label}</span>
      <Badge amount={amount} className={styles.badge} />
    </div>
  )
}
interface CollectedProps {
  isDragging: boolean
}
